import React, { ReactElement } from 'react'
import plural from '@mindfulchefuk/utils/plural'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import { ProductWithQuantity } from '@mindfulchefuk/query'

import { Box, Flex, Text } from '@mindfulchefuk/design-system'
import { CartNavigationSubSummary } from '@mindfulchefuk/features/Cart/components/CartNavigation/CartNavigationSubSummary'

type Props = {
  maxRecipes: number
  portionCountPerMeal: number
  products: ProductWithQuantity[]
  recipes: Recipe[]
  totalProducts: number
  totalRecipes: number
  showSubText?: boolean
}

export const CartNavigationSummary = ({
  maxRecipes,
  recipes = [],
  products = [],
  portionCountPerMeal,
  totalProducts,
  totalRecipes,
  showSubText = true,
}: Props): ReactElement => {
  const recipeText = plural('recipe')(totalRecipes)

  const headingText =
    totalRecipes < maxRecipes
      ? `${totalRecipes} ${recipeText} - add up to ${maxRecipes}`
      : `${totalRecipes} ${recipeText} - recipe box full`

  return (
    <Flex
      align="center"
      mr={28}
      data-testid="cart-navigation-summary"
      display={['none', 'flex']}
      width={208}
    >
      <Box>
        <Text as="h1" variant="body-sm-500-caps" color="aubergine" mb={10}>
          {headingText}
        </Text>
        {showSubText && (
          <CartNavigationSubSummary
            portionCountPerMeal={portionCountPerMeal}
            recipes={recipes}
            products={products}
            totalProducts={totalProducts}
            maxRecipes={maxRecipes}
            totalRecipes={totalRecipes}
          />
        )}
      </Box>
    </Flex>
  )
}
