import { ProductWithQuantity } from '@mindfulchefuk/query'

import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import { useRouter } from 'next/router'
import plural from '@mindfulchefuk/utils/plural'
import { Text } from '@mindfulchefuk/design-system'
import React, { ReactElement } from 'react'

type Props = {
  portionCountPerMeal: number
  products: ProductWithQuantity[]
  recipes: Recipe[]
  totalProducts: number
  maxRecipes: number
  totalRecipes: number
}

export const CartNavigationSubSummary = ({
  recipes,
  products,
  portionCountPerMeal,
  totalProducts,
  maxRecipes,
  totalRecipes,
}: Props): ReactElement => {
  const router = useRouter()
  const totalIngredients = recipes.reduce(
    (all, item) => all + item.ingredients.length,
    0
  )
  const inRecipeSection = router.query.page === 'recipes'

  const people = plural('person', 'people')(portionCountPerMeal)
  const shopProducts = plural(
    'Ready to Go item',
    'Ready to Go items'
  )(totalProducts)
  const containsProducts = !!products.length
  const maxRecipesReached = totalRecipes === maxRecipes

  let subSummaryText

  if (!inRecipeSection && !containsProducts && maxRecipesReached) {
    subSummaryText = 'Would you like any frozen products?'
  } else if (inRecipeSection && !containsProducts && !maxRecipesReached) {
    subSummaryText = `${totalIngredients} fresh ingredients for ${portionCountPerMeal} ${people}`
  } else {
    subSummaryText = `${totalProducts} ${shopProducts}`
  }
  return (
    <Text as="h1" variant="body-sm" color="pepper">
      {subSummaryText}
    </Text>
  )
}
