import React, { ReactElement, VoidFunctionComponent } from 'react'
import { RecipeWithQuantity } from '@mindfulchefuk/features/Recipes/interfaces'
import { ProductWithQuantity } from '@mindfulchefuk/query'

import { useNewestItem } from '@mindfulchefuk/features/Cart/useNewestItem'
import { CartNavigationSummary } from '@mindfulchefuk/features/Cart/components/CartNavigation/CartNavigationSummary'
import { Flex } from '@mindfulchefuk/design-system'
import { useOrderPreview } from '@mindfulchefuk/features/Previews/hooks/useOrderPreview'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { useDeliveryDate } from '@mindfulchefuk/features/Delivery/hooks/useDeliveryDate'
import { isSingleCheckoutJourney } from '@mindfulchefuk/utils/isSingleCheckout'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'
import { createRecipeContents } from '@mindfulchefuk/features/Previews/helpers/createRecipeContents'
import { useProductCart } from '@mindfulchefuk/features/Cart/components/CartDrawer/hooks/useProductCart'
import { ISODate } from '@mindfulchefuk/lib/dateFormats'
import { mapProductsWithQuantityToProductContents } from '@mindfulchefuk/features/Cart/components/transformers'
import { CartButton } from './CartButton'
import { CartFlag } from './CartFlag'
import { SingleCheckoutCartNavigationSummary } from './SingleCheckoutCartNavigationSummary'

type Props = {
  products?: ProductWithQuantity[]
  recipes: RecipeWithQuantity[]
  totalRecipes: number
  totalProducts?: number
  onClick: () => void
  portionCountPerMeal: number
  maxRecipes: number
  lastAddedRecipe: RecipeWithQuantity
  showSubText?: boolean
}

export const CartNavigation: VoidFunctionComponent<Props> = ({
  portionCountPerMeal,
  products,
  recipes = [],
  totalRecipes,
  totalProducts = 0,
  onClick,
  maxRecipes,
  lastAddedRecipe,
  showSubText = true,
}: Props): ReactElement => {
  const newestItem = useNewestItem(products, lastAddedRecipe)

  const customerId = getCustomerId()
  const deliveryDate = useDeliveryDate() as ISODate
  const { isNewCustomer } = useIsNewCustomer()

  const recipeContents = createRecipeContents(recipes)

  const { totalProducts: totalProductsInCart, productsInCart } =
    useProductCart()

  const productContents =
    mapProductsWithQuantityToProductContents(productsInCart)

  const { data: orderPreview, isFetched } = useOrderPreview({
    customerId,
    deliveryDate,
    recipeContents,
    productContents,
  })

  const purchaseValue = orderPreview?.purchaseValue
  const isSingleCheckoutEnabled = isSingleCheckoutJourney(deliveryDate)

  return (
    <Flex position="relative" align="center">
      {isSingleCheckoutEnabled && !isNewCustomer && isFetched ? (
        <>
          <SingleCheckoutCartNavigationSummary
            key="sc-cart-navigation-summary"
            totalPrice={purchaseValue}
            minSpend={orderPreview?.minimumOrderValue}
          />
          <CartButton newestItem={newestItem} onClick={onClick}>
            {totalProductsInCart + totalRecipes}
          </CartButton>
        </>
      ) : (
        <>
          <CartNavigationSummary
            key="cart-navigation-summary"
            maxRecipes={maxRecipes}
            recipes={recipes}
            portionCountPerMeal={portionCountPerMeal}
            products={products}
            totalProducts={totalProducts}
            totalRecipes={totalRecipes}
            showSubText={showSubText}
          />
          <CartButton newestItem={newestItem} onClick={onClick}>
            {totalProducts + totalRecipes}
          </CartButton>
        </>
      )}
      <CartFlag item={newestItem} />
    </Flex>
  )
}
