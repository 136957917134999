import {
  RecipeContent,
  RecipePropsInDelivery,
} from '@mindfulchefuk/features/Delivery/interfaces'
import { RecipeWithQuantity } from '@mindfulchefuk/features/Recipes/interfaces'

export const createRecipeContents = (
  recipes: RecipeWithQuantity[] | RecipePropsInDelivery[]
): RecipeContent[] => {
  if (!recipes?.length) return []

  const recipeContents = recipes.map((recipe) =>
    new Array(recipe.quantity).fill({
      recipe_id: recipe.id,
      portions: recipe.portions,
      price: recipe.price,
    })
  )

  return recipeContents.flatMap((content) => content)
}
