import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { RecipeContent } from '@mindfulchefuk/features/Delivery/interfaces'
import { ISODate } from '@mindfulchefuk/lib/dateFormats'
import { postOrderPreview } from '@mindfulchefuk/services/postOrderPreview'
import { ProductContent } from '@mindfulchefuk/features/Products/interfaces'

type TOrderPreviewParams = {
  customerId: string
  deliveryDate: ISODate
  recipeContents: RecipeContent[]
  productContents: ProductContent[]
}

export type TOrderPreviewResponse = {
  customerId: string
  paymentDate: ISODate
  purchaseValue: number | null
  discountAmount: number | null
  creditBalanceAmount: number | null
  externalPaymentAmount: number | null
  shippingAmount: number | null
  minimumOrderValue: number
}

export const useOrderPreview = ({
  customerId,
  deliveryDate,
  recipeContents,
  productContents,
}: TOrderPreviewParams): UseQueryResult<TOrderPreviewResponse, unknown> => {
  return useQuery({
    queryKey: [
      'single-checkout-order-preview',
      {
        customerId,
        deliveryDate,
        recipeContents,
        productContents,
      },
    ],
    queryFn: () =>
      postOrderPreview({
        customerId,
        deliveryDate,
        recipeContents,
        productContents,
      }),
    enabled: !!customerId && !!deliveryDate,
  })
}
