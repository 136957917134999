import React, { ReactElement } from 'react'
import { Flex, PriceText, Text } from '@mindfulchefuk/design-system'

type Props = {
  totalPrice: number
  minSpend: number
}

export const SingleCheckoutCartNavigationSummary = ({
  totalPrice,
  minSpend,
}: Props): ReactElement => {
  const isMinPriceReached = totalPrice >= minSpend
  return (
    <Flex
      mr={10}
      data-testid="sc-cart-navigation-summary"
      direction="column"
      justifyContent="flex-end"
    >
      <PriceText price={totalPrice} justifyItems="flex-end" ml="auto" mb={4} />
      {!isMinPriceReached && (
        <Text as="h1" variant="body-sm" color="veg1" mb={10}>
          minimum{' '}
          <PriceText
            price={minSpend}
            justifyItems="flex-end"
            ml="auto"
            mb={4}
            color="veg1"
          />
        </Text>
      )}
    </Flex>
  )
}
