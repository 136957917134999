import { PaymentsSingleCheckoutOrderPreview } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { spraypaintErrorMessage } from '@mindfulchefuk/utils/spraypaintUtils'
import getPaymentDate from '@mindfulchefuk/helpers/js/getPaymentDate'
import { RecipeContent } from '@mindfulchefuk/features/Delivery/interfaces'
import { ProductContent } from '@mindfulchefuk/features/Products/interfaces'

type OrderPreviewParamsType = {
  customer_id: string
  delivery_date: string
  recipe_contents: RecipeContent[]
  product_contents: ProductContent[]
}
export type PostOrderPreviewProps = {
  customerId: string
  deliveryDate: string
  recipeContents: RecipeContent[]
  productContents: ProductContent[]
}

export const postOrderPreview = async ({
  customerId,
  deliveryDate,
  recipeContents,
  productContents,
}: PostOrderPreviewProps) => {
  const OrderPreviewParams: OrderPreviewParamsType = {
    customer_id: customerId,
    delivery_date: deliveryDate,
    recipe_contents: recipeContents,
    product_contents: productContents,
  }

  const orderPreview = new PaymentsSingleCheckoutOrderPreview(
    OrderPreviewParams
  )

  try {
    const result = await orderPreview.save()

    if (!result) {
      throw new Error(spraypaintErrorMessage(orderPreview))
    }

    const {
      customer_id: responseCustomerId,
      delivery_date: responseDeliveryDate,
      purchase_value: purchaseValue,
      discount_amount: discountAmount,
      credit_balance_amount: creditBalanceAmount,
      external_payment_amount: externalPaymentAmount,
      shipping_amount: shippingAmount,
      minimum_order_value: minimumOrderValue,
    } = orderPreview

    return {
      customerId: responseCustomerId,
      paymentDate: getPaymentDate(responseDeliveryDate),
      purchaseValue: purchaseValue ? parseFloat(purchaseValue) : null,
      discountAmount: discountAmount ? parseFloat(discountAmount) : null,
      creditBalanceAmount: creditBalanceAmount
        ? parseFloat(creditBalanceAmount)
        : null,
      externalPaymentAmount: externalPaymentAmount
        ? parseFloat(externalPaymentAmount)
        : null,
      shippingAmount: shippingAmount ? parseFloat(shippingAmount) : null,
      minimumOrderValue: minimumOrderValue ? parseFloat(minimumOrderValue) : 0,
    }
  } catch (error) {
    errorHandler(error)
  }
}
